import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import GoogleMapsEmbed from './GoogleMapEmbed';

const GOOGLE_FORMS_CONTACT_US_URL = 'https://forms.gle/zHLZMz5PKyHAgriE7'

const Contact = () => {

    const coordinates = { lat: 39.800617, lng: -76.984217 };

    return (
        <Container className="d-flex  flex-column flex-grow-1" >
            <Row>
                <Col style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <Card>
                        <Card.Header>
                            <span style={{ float: 'left' }}>
                                <h1>Contact</h1>
                            </span>
                            <span style={{ float: 'right' }}>
                                <Button
                                    variant="primary"
                                    style={{ backgroundColor: '#fbb514', color: '#1651a9', borderColor: '#1651a9' }}
                                    href={GOOGLE_FORMS_CONTACT_US_URL}
                                    target='_blank'
                                >Say Hello</Button>
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <p>
                                
                            </p>
                            <p>
                                <b>Email:</b>
                                <br />
                                info@sybr.org
                            </p>
                            <p>
                                <b>Phone:</b>
                                <br />
                                410.635.0026
                            </p>
                            <p>
                                <b>Address:</b>
                                <br />
                                1 Center Sq. Suite 203C Hanover Pa 17331
                                <br />
                                <GoogleMapsEmbed coordinates={coordinates} />
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;