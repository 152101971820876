import React, { Component } from 'react';
import SYBRLOGO from '../assets/sybrlogo.png'

const GOOGLE_MAPS_API_KEY = 'AIzaSyCnGgwOJjinr8IkgV7OES7L7UjpNEEjQ3g'

class GoogleMapsEmbed extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
    window.initMap = this.initMap;
    document.body.appendChild(googleMapScript);
  }

  componentWillUnmount() {
    window.initMap = null;
  }
å
  initMap = () => {
    const { lat, lng } = this.props.coordinates; // Example: { lat: 39.800617, lng: -76.984217 }
    const mapOptions = {
      center: { lat, lng },
      zoom: 16,
    };

    const map = new window.google.maps.Map(this.mapRef.current, mapOptions);

    const markerIcon = {
      url: SYBRLOGO, // Replace with the path to your custom icon
      scaledSize: new window.google.maps.Size(80, 30), // Adjust the size of the icon
    };

    new window.google.maps.Marker({
      position: { lat, lng },
      map: map,
      icon: markerIcon,
    });
  };

  render() {
    return <div ref={this.mapRef} style={{ width: '100%', height: '400px' }}></div>;
  }
}

export default GoogleMapsEmbed;

