import React, { useState } from 'react';
import McTMSHeader from './views/McTMSHeader'
import MailCarrierTMSPage from './views/MailCarrierTMSPage';
import NavigationBar from './views/NavigationBar';
import Home from './views/Home';
import Services from './views/Services';
import Body from './views/Body';
import Footer from './views/Footer';
import NotFound from './views/NotFound';
import Banner from './views/Banner'
import Contact from './views/Contact';
// import LoadEyePage from './views/LoadEyePage';
// import Products from './views/Products';

function App() {
  const [view, setView] = useState();

  if (!view || view === 'mailcarriertms') {
    return (
      <Body>
        <McTMSHeader />
        <MailCarrierTMSPage header={
          <>
            <NavigationBar setView={setView} />
            <Banner />
          </>
        } footer={
          <>
            <Banner />
            <Footer />
          </>
        }>
        </MailCarrierTMSPage>
      </Body>
    )
  }

  if (view === 'contact') {
    return (
      <Body>
        <NavigationBar setView={setView} />
        <Banner />
        <Contact />
        <Banner />
        <Footer />
      </Body>
    )
  }

  if (view === 'home') {
    return (
      <Body>
        <NavigationBar setView={setView} />
        <Banner />
        <Home />
        <Banner />
        <Footer />
      </Body>
    )
  }

  if (view === 'services') {
    return (
      <Body>
        <NavigationBar setView={setView} />
        <Banner />
        <Services />
        <Banner />
        <Footer />
      </Body>
    )
  }

  return (
    <Body>
      <NavigationBar setView={setView} />
      <Banner />
      <NotFound />
      <Banner />
      <Footer />
    </Body>
  )
}

export default App;
