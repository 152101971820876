import { Container, Row, Col, Card } from 'react-bootstrap';
import F0 from '../assets/home/f0.png'
import F1 from '../assets/home/f1.png'
import F2 from '../assets/home/f2.png'
import F3 from '../assets/home/f3.png'
import F4 from '../assets/home/f4.png'

const Home = () => {
    return (
        <Container className="d-flex" >
            <Row>
                <Col style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <Card>
                        <Card.Header>
                            <h1>SYBR</h1>
                            <h4 className="subtitle">
                                We solve complex problems with intuitive solutions.
                            </h4>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Founded in 2018, SYBR is a dynamic technology company that has swiftly emerged as a leader in delivering high-quality solutions to its clients.
                                With a wealth of experience in diverse areas ranging from landing pages and store fronts to mobile applications and enterprise software solutions,
                                SYBR possesses a comprehensive understanding of the digital landscape. In addition to providing custom solutions,
                                SYBR also develops its own software as a service (SaaS) products. By combining innovative thinking, cutting-edge technologies,
                                and a customer-centric approach, SYBR ensures that its solutions meet the unique needs of each client. With a dedicated team of professionals,
                                SYBR is committed to delivering excellence, driving digital transformation, and helping businesses thrive in the ever-evolving technological landscape.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Purpose and Mission
                            </Card.Title>
                            <Card.Text>
                                At SYBR, our mission is to utilize technology as a powerful force for positive change.
                                We are dedicated to delivering high-quality solutions and services that address critical challenges and
                                make a lasting impact. Through our expertise in software development, innovative mobile applications,
                                and enterprise solutions, we strive to empower individuals, organizations, and communities worldwide.
                                From supporting humanitarian missions and protecting fishermen from threats to aiding in the defense of
                                nations against aggression, we are committed to leveraging technology to safeguard lives, enable growth,
                                and drive positive transformations. Our mission is driven by a deep sense of responsibility and a passion
                                for using our skills to make a difference in the world. Together with our clients and partners, we forge a path
                                toward a better future through the seamless integration of technology and human ingenuity.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Proud Moments
                            </Card.Title>
                            <Card.Text>
                                SYBR takes pride in its achievements and recognition, particularly in projects that contribute to humanitarian
                                missions and global security. Notably, SYBR played a pivotal role in supporting these efforts in the Philippines and Ukraine.
                                In the Philippines, SYBR developed a mobile application to safeguard fishermen against potential threats arising from tensions in the South China Sea.
                                This initiative aimed to enhance their safety and protect their livelihoods. In Ukraine, SYBR's project, named Orion,
                                was instrumental in tracking Russian troop movements following the Russia's invasion of the Ukraine. The remarkable impact of
                                Orion quickly garnered recognition from special forces groups, leading to its integration for the protection of Ukrainian civilians
                                against Russian aggression. These achievements highlight SYBR's commitment to
                                leveraging technology for the greater good, earning the respect and appreciation of both humanitarian organizations and defense forces.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Audience
                            </Card.Title>
                            <Card.Text>
                                <p>
                                    SYBR offers a range of information technology services tailored to meet the needs of both general audiences and USPS
                                    mail carriers. For general audiences, we provide a wide array of customizable solutions designed to support individuals,
                                    small businesses, and large enterprises in their IT endeavors. Our team of experts excels in various domains,
                                    including software development, cloud computing, cybersecurity, and more. We take pride in delivering high-quality,
                                    innovative solutions that are tailored to address the unique requirements of each client.
                                </p>
                                <p>
                                    In addition to our generic services, SYBR has developed a specialized product called Mail Carrier TMS specifically
                                    for USPS mail carriers. Mail Carrier TMS is a comprehensive technology platform that streamlines mail carriers'
                                    operations, enhances productivity, and simplifies their daily tasks. With features such as route optimization,
                                    package tracking, and efficient communication tools, Mail Carrier TMS is designed to empower USPS mail carriers
                                    and improve their overall efficiency and effectiveness.
                                </p>
                                <p>
                                    Whether you are seeking general IT solutions or require the specialized benefits of Mail Carrier TMS,
                                    SYBR is dedicated to providing cutting-edge technology and exceptional service to help you achieve your goals.
                                </p>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Unique Selling Proposition
                            </Card.Title>
                            <Card.Text>
                                At SYBR, our Unique Selling Proposition (USP) lies in our ability to seamlessly bridge the gap between cutting-edge
                                technology and real-world business solutions. We differentiate ourselves by combining our deep industry knowledge,
                                innovative thinking, and technical expertise to deliver customized, high-quality solutions that drive tangible
                                results for our clients. With a customer-centric approach, we prioritize understanding the unique needs and goals of
                                each client, allowing us to create tailored strategies and develop scalable applications that perfectly align with
                                their vision. Our commitment to excellence, attention to detail, and unwavering focus on delivering value set us apart,
                                empowering businesses to thrive in the digital landscape and achieve sustainable success. By choosing SYBR, clients gain
                                a trusted partner who combines advanced technology with practical business acumen to unlock their full potential and
                                overcome the challenges of the modern world.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Core Values
                            </Card.Title>
                            <Card.Text>
                                At SYBR, our core values revolve around the principles of hard work, adaptability, and continuous improvement. These values guide us in our pursuit of excellence and form the foundation of our company culture. Our core values include:
                                <ul>
                                    <li><b>Hard Work Becomes Talent:</b> We believe that sustained effort and dedication can transform hard work into talent. We encourage a strong work ethic, perseverance, and a commitment to constantly improve and refine our skills.</li>
                                    <li><b>Adapt and Overcome:</b> We embrace change and understand the importance of adaptability in a rapidly evolving technological landscape. We are resilient and agile, always ready to embrace new challenges and find innovative solutions.</li>
                                    <li><b>Leave It Better Than You Found It:</b> We are committed to making a positive impact in everything we do. We strive to leave a lasting impression by continuously seeking opportunities to enhance processes, deliver exceptional solutions, and make a difference for our clients, communities, and the environment.</li>
                                </ul>
                                These core values shape our actions, decisions, and interactions within SYBR. They inspire us to go above and beyond, continuously improve, and maintain a strong focus on delivering exceptional results. By embracing these values, we create a culture of excellence and drive the success of our clients, our team members, and our organization as a whole.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Meet the Founder
                            </Card.Title>
                            <Card.Text>
                                <p>
                                    Michael possesses a deep appreciation for technology and has an adventurous spirit.
                                    Together with his beloved wife Kelly, they embarked on a new chapter when they moved to
                                    Hanover, PA, renowned as "The snack capital of the world." The irresistible aromas from Utz Potato Chips and Snyder's
                                    Pretzels factories fill the air, adding a touch of deliciousness to their everyday lives.
                                </p>
                                <p>
                                    Beyond Michael's dedication to SYBR, he and his wife Kelly share an unwavering love for the great outdoors, especially on warm summer days.
                                    They are passionate anglers, and their favorite pastime is embarking on kayaking adventures, fully equipped with fishing gear, in search of
                                    new and exciting fishing spots. Whether it's the exhilaration of the catch or the serenity of the water, their mutual love for fishing creates
                                    cherished moments of relaxation, connection, and pure joy.
                                    Together, they find solace in nature and embrace the beauty of the world around them, forging unforgettable memories on their fishing escapades.
                                </p>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body style={{ backgroundColor: '#082244' }} >
                            <Container className="d-flex flex-wrap justify-content-center align-items-center ">
                                <Card className="image-card"  style={{ width: '12rem', margin:'1rem' }}>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Card.Img src={F0} />
                                    </Card.Body>
                                </Card>
                                <Card className="image-card"  style={{ width: '12rem', margin:'1rem' }}>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Card.Img src={F2} />
                                    </Card.Body>
                                </Card>
                                <Card className="image-card"  style={{ width: '12rem', margin:'1rem' }}>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Card.Img src={F1}  />
                                    </Card.Body>
                                </Card>
                                <Card className="image-card" style={{ width: '12rem', margin:'1rem' }}>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Card.Img src={F3} />
                                    </Card.Body>
                                </Card>
                                <Card className="image-card" style={{ width: '12rem', margin:'1rem' }}>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Card.Img src={F4} />
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;