import './style.css'
import React from 'react';
import sybrvideo from '../assets/sybrvideo.mov'

const McTMSHeader = ({ children }) => {



  return (
    <header className="video-header">
        <video className="video-background" src={sybrvideo} autoPlay loop muted preload="metadata" controls={false} />
      {children}
    </header>
  );
};

export default McTMSHeader;