import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import './FlippableCard.css'

const FlippableCard = ({front,back}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Card
      onClick={handleCardClick}
      className={`flippable-card ${isFlipped ? 'flipped' : ''} image-card`}
      
    >
      <div className="front">{front}</div>
      <div className="back">{back}</div>
    </Card>
  );
};

export default FlippableCard;
