// import { useState } from 'react';
import { Container, Row, Col, Card, NavLink, Image, Button } from 'react-bootstrap';
// import { ListGroup, ListGroupItem, Badge, } from 'react-bootstrap';
import APPLE from '../assets/tools/apple.png'
import LINUX from '../assets/tools/linux.png'
import WINDOWS from '../assets/tools/windows.png'
import ANDROID from '../assets/tools/android.png'
import AWS from '../assets/tools/aws.png'
import GOOGLECLOUD from '../assets/tools/googlecloud.png'
import CLOUDFLARE from '../assets/tools/cloudflare.png'
import AUTH0 from '../assets/tools/auth0.png'
import BUDDYWORKS from '../assets/tools/buddyworks.png'
import GIT from '../assets/tools/git.png'
import GITHUB from '../assets/tools/github.png'
import BITBUCKET from '../assets/tools/bitbucket.png'
import NODE from '../assets/tools/node.png'
import REACT from '../assets/tools/react.png'
import JS from '../assets/tools/js.png'
import HTML from '../assets/tools/html.png'
import CSS from '../assets/tools/css.png'
import PHP from '../assets/tools/php.png'
import PYTHON from '../assets/tools/python.png'
import MYSQL from '../assets/tools/mysql.png'
import MSSQL from '../assets/tools/mssql.png'
import GRAPHQL from '../assets/tools/graphql.png'
import WORDPRESS from '../assets/tools/wordpress.png'
import MONGO from '../assets/tools/mongodb.png'
import AZURE from '../assets/tools/azure.png'
import JEST from '../assets/tools/jest.png'

const GOOGLE_FORMS_CONTACT_US_URL = 'https://forms.gle/zHLZMz5PKyHAgriE7'

const Services = () => {
    // const [showService, shouldShowService] = useState();
    return (
        <Container className="d-flex" >
            <Row>
                <Col style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <Card>
                        <Card.Header>
                            <h1>Services</h1>
                            <h4 className="subtitle">
                                We help build your vision.
                            </h4>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                Our Services
                            </Card.Title>
                            <Card.Text>
                                SYBR offers a comprehensive range of cutting-edge services to empower businesses in the digital era.
                                With a strong focus on innovation and customer satisfaction, SYBR leverages its expertise in software development,
                                cloud computing, data analytics, and cybersecurity to deliver tailored solutions to its clients.
                                From crafting intuitive web and mobile applications to implementing robust infrastructure and ensuring data security,
                                SYBR is dedicated to driving digital transformation and helping organizations thrive in today's competitive landscape.
                                With a team of skilled professionals and a commitment to excellence, SYBR enables businesses to unlock their full potential and
                                achieve sustainable growth through advanced technological solutions.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Tools for the Job
                            </Card.Title>
                            <Card.Text>
                                SYBR, a technology company committed to excellence, harnesses the power of cutting-edge tools to deliver high-quality solutions to its customers.
                                By leveraging advanced software, frameworks, and platforms, SYBR ensures that every project is executed with precision and innovation.
                                From the initial stages of development to the final deployment, SYBR's team utilizes a diverse toolbox of technical resources to optimize performance, enhance user experience,
                                and meet client objectives. By staying at the forefront of technological advancements, SYBR is able to offer state-of-the-art solutions that are scalable, secure, and efficient.
                                Through rigorous testing, continuous integration, and industry best practices, SYBR guarantees that its solutions not only meet but exceed customer expectations.
                                By embracing technology and its transformative potential, SYBR empowers organizations to thrive in a digital world, driving growth, efficiency, and success.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body style={{ backgroundColor: '#082244' }}>
                            <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink href={'https://developer.apple.com/'} target={'_blank'}><Image rounded src={APPLE} alt={'apple'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.linux.org/'} target={'_blank'}><Image rounded src={LINUX} alt={'linux'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.microsoft.com/'} target={'_blank'}><Image rounded src={WINDOWS} alt={'windows'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://developer.android.com/'} target={'_blank'}><Image rounded src={ANDROID} alt={'android'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://aws.amazon.com/'} target={'_blank'}><Image rounded src={AWS} alt={'aws'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://cloud.google.com/'} target={'_blank'}><Image rounded src={GOOGLECLOUD} alt={'google cloud'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://azure.microsoft.com/'} target={'_blank'}><Image rounded src={AZURE} alt={'azure'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.cloudflare.com/'} target={'_blank'}><Image rounded src={CLOUDFLARE} alt={'cloudflare'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://auth0.com/'} target={'_blank'}><Image rounded src={AUTH0} alt={'auth0'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://nodejs.org/'} target={'_blank'}><Image rounded src={NODE} alt={'nodejs'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://reactjs.org/'} target={'_blank'}><Image rounded src={REACT} alt={'reactjs'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://developer.mozilla.org/en-US/docs/Web/JavaScript'} target={'_blank'}><Image rounded src={JS} alt={'java script'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://developer.mozilla.org/en-US/docs/Web/HTML'} target={'_blank'}><Image rounded src={HTML} alt={'html'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://developer.mozilla.org/en-US/docs/Web/css'} target={'_blank'}><Image rounded src={CSS} alt={'css'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.php.net/'} target={'_blank'}><Image rounded src={PHP} alt={'php'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.python.org/'} target={'_blank'}><Image rounded src={PYTHON} alt={'python'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://jestjs.io/'} target={'_blank'}><Image rounded src={JEST} alt={'jest'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://git-scm.com/'} target={'_blank'}><Image rounded src={GIT} alt={'git'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://github.com/'} target={'_blank'}><Image rounded src={GITHUB} alt={'github'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://bitbucket.org/'} target={'_blank'}><Image rounded src={BITBUCKET} alt={'bitbucket'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://buddy.works/'} target={'_blank'}><Image rounded src={BUDDYWORKS} alt={'buddy.works'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://wordpress.com/'} target={'_blank'}><Image rounded src={WORDPRESS} alt={'wordpress'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.mongodb.com/'} target={'_blank'}><Image rounded src={MONGO} alt={'mongo'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://graphql.org/'} target={'_blank'}><Image rounded src={GRAPHQL} alt={'graphql'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://mysql.com'} target={'_blank'}><Image rounded src={MYSQL} alt={'mysql'} style={{ margin: '1em', height: '70px' }} /></NavLink>
                                <NavLink href={'https://www.microsoft.com/en-us/sql-server/sql-server-2019'} target={'_blank'}><Image rounded src={MSSQL} alt={'microsoft sql'} style={{ margin: '1em', height: '100px' }} /></NavLink>
                            </Container>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Agile Product Delivery
                            </Card.Title>
                            <Card.Text>
                                <p>
                                    SYBR fully embraces agile project management methodologies to drive successful project delivery.
                                    By leveraging agile principles and practices, SYBR ensures that its teams collaborate closely with clients,
                                    stakeholders, and end-users throughout the project lifecycle. SYBR begins by breaking down projects into
                                    manageable sprints, each focused on delivering specific features or functionalities. This iterative approach
                                    allows for regular feedback and adjustments, ensuring that the final solution aligns with client expectations.
                                </p>
                                <p>
                                    SYBR's project teams prioritize open and transparent communication, facilitating frequent interactions
                                    with clients to gather requirements, provide updates, and seek feedback. Through agile ceremonies such
                                    as daily stand-up meetings, sprint planning, and retrospectives, SYBR encourages cross-functional collaboration
                                    and continuous improvement.
                                </p>
                                <p>
                                    By using agile project management, SYBR emphasizes adaptability and embraces changes to requirements,
                                    enabling clients to respond to evolving market needs. This iterative approach also allows SYBR to deliver
                                    working software incrementally, ensuring early value realization and reducing time-to-market.
                                </p>
                                <p>
                                    Furthermore, SYBR utilizes agile project management tools and techniques to track progress,
                                    manage tasks, and ensure efficient resource allocation. By continuously assessing performance and
                                    applying lessons learned, SYBR drives process improvements and enhances project outcomes.
                                </p>
                                <p>
                                    In summary, SYBR's adoption of agile project management practices enables it to deliver
                                    high-quality solutions that meet client needs, promote collaboration, and adapt to changing requirements,
                                    resulting in successful project outcomes.
                                </p>

                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title>
                                Cost Assessment
                            </Card.Title>
                            <Card.Text>
                                SYBR is committed to creating win-win relationships with its clients, and pricing and packages play a crucial
                                role in achieving this objective. By conducting a thorough analysis of client needs,
                                SYBR ensures that the pricing structure and packages are tailored to provide maximum value and align with
                                the client's budget and goals. The goal is to establish a transparent and collaborative partnership,
                                where both SYBR and the client benefit from the services rendered. With a focus on delivering
                                high-quality solutions within the agreed-upon timeline and cost, SYBR aims to foster long-term
                                relationships based on mutual success and satisfaction. By prioritizing client needs and providing
                                competitive pricing options, SYBR strives to create a win-win scenario that enables clients to achieve
                                their desired outcomes while experiencing the expertise and professionalism that SYBR brings to the table.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Button variant="primary"
                                        style={{ backgroundColor: '#fbb514', color: '#1651a9', borderColor: '#1651a9' }}
                                        href={GOOGLE_FORMS_CONTACT_US_URL}
                                        target='_blank'
                                    >Contact SYBR Today</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Services;