
import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { codocs } from "../assets/docs/co";

function Footer() {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    if (showTerms) {
      return (
        <TermsModal
          show={showTerms}
          onHide={() => setShowTerms(false)}
        />
      )
    }
    if (showPrivacy) {
      return (
        <PrivacyModal
          show={showPrivacy}
          onHide={() => setShowPrivacy(false)}
        />
      )
    }
    return (
      <footer className="footer d-flex justify-content-center align-items-center" style={{ padding: '25px',  backgroundColor: '#2257a9' }}>
        <Container >
          <Row>
            <Col sm={12} lg={4} className="text-center text-lg-start mb-4 mb-lg-0">
              <p>&copy; 2018-2023 SYBR, LLC - All rights reserved.</p>
            </Col>
            <Col sm={12} lg={8} className="text-center text-lg-end ">
              1 Center Sq. Suite 203C Hanover Pa 17331
              {' | '}
              <Button variant="link" style={{ color: 'black' }} onClick={() => setShowTerms(true)} >Terms and Conditions</Button>
              {' | '}
              <Button variant="link" style={{ color: 'black' }} onClick={() => setShowPrivacy(true)} >Privacy Policy</Button>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
  
  function TermsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {codocs.termsconditions}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function PrivacyModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {codocs.privacypolicy}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

  export default Footer;