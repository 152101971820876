import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import sybrlogo from '../assets/logo.png';
import './style.css'

const GOOGLE_FORMS_CONTACT_US_URL = 'https://forms.gle/zHLZMz5PKyHAgriE7'
const GOOGLE_FORMS_SUPPORT_URL = 'https://forms.gle/KWJGHZ8qxXQf2XrNA'

function NavigationBar({setView}) {
    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" style={{ backgroundColor: '#2257a9' }}>
            <Container>
                <Navbar.Brand onClick={()=>setView('home')}>
                    <img src={sybrlogo} alt={'logo'} className={'logo'} />
                </Navbar.Brand>
                <Navbar.Toggle className="navbar-toggle" aria-controls="navbarScroll" style={{ float: 'right', backgroundColor: 'rgb(251,181,20)', borderColor: 'rgb(251,181,20)' }} />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="justify-content-center me-auto" justify >
                        <Nav.Item as="li">
                            <Nav.Link  onClick={()=>setView('home')} className="nav-link me-auto">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Link  onClick={()=>setView('contact')} className="nav-link ml-auto">Contact</Nav.Link>
                        {/* <Nav.Link href="/products" className="nav-link ml-auto">Products</Nav.Link> */}
                        {/* <Nav.Link href="/products/loadeye" className="nav-link ml-auto" style={{ whiteSpace: 'nowrap' }}> LoadEye </Nav.Link> */}
                        <Nav.Link  onClick={()=>setView('mailcarriertms')} className="nav-link ml-auto" style={{ whiteSpace: 'nowrap' }}> Mail Carrier TMS </Nav.Link>
                        <Nav.Link  onClick={()=>setView('services')} className="nav-link ml-auto">Services</Nav.Link>
                        <Button
                            variant="primary"
                            style={{ backgroundColor: '#fbb514', color: '#1651a9', borderColor: '#1651a9' }}
                            href={GOOGLE_FORMS_CONTACT_US_URL}
                            target='_blank'
                        >Contact Us</Button>
                        <Button
                            variant="primary"
                            style={{ backgroundColor: '#fbb514', color: '#1651a9', borderColor: '#1651a9' }}
                            href={GOOGLE_FORMS_SUPPORT_URL}
                            target='_blank'
                        >Support</Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar