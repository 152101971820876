const Body = ({ children }) => {
    return (
        <div style={{
            position: 'absolute',
            backgroundColor:'#eee',
            top: 0,
            left: 0,
            width: '100%',
            minWidth: '350px',
        }}>
            {children}
        </div>
    );
};


export default Body;