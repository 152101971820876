import React from "react"
import mmslogo from '../assets/customer/mms.png'
import semiimg from '../assets/truck.png'
import docimg from '../assets/doc.png'
import graphimg from '../assets/graph.png'
import { Container, Card, Button, ListGroup, ListGroupItem, Row, Col, Badge, Nav } from 'react-bootstrap';
import { BsFillArrowRightSquareFill, BsCheckCircleFill } from 'react-icons/bs'
import { GiHorizontalFlip } from 'react-icons/gi'
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import FlippableCard from "./FlippableCard"
import GMAPS_IMG from '../assets/b2b/googlemaps.png'
import EIA_IMG from '../assets/b2b/eia.png'
import NWS_IMG from '../assets/b2b/nationalweatherservice.png'
import FOURKITES_IMG from '../assets/b2b/fourkites.png'
import KLEINSCHMIDT_IMG from '../assets/b2b/kleinschmidt.jpg'
import PCMILER_IMG from '../assets/b2b/pcmiler.png'
import USPS_IMG from '../assets/b2b/usps.png'
import SAMSARA_IMG from '../assets/b2b/samsara.png'


const GOOGLE_FORMS_CONTACT_US_URL = 'https://forms.gle/zHLZMz5PKyHAgriE7'

function Message() {
  const colStyle = {
    margin: '1rem',
    color: '#fff'
  }
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ paddingTop: '100px', paddingBottom: '100px' }} >
      <Row>
        <Col style={colStyle}>
          <h1>Software built for Mail Carriers by Mail Carriers</h1>
          <h4 className="subtitle">
            Mail Carrier TMS saves time and money, and improves performance and visibility
          </h4>
          <Button
            variant="primary"
            style={{ backgroundColor: '#fbb514', color: '#1651a9', borderColor: '#1651a9' }}
            href={GOOGLE_FORMS_CONTACT_US_URL}
          >Get Started</Button>
        </Col>
      </Row>
    </Container>
  )
}

function CallToActionPlan() {

  const colStyle = {
    margin: '1rem',
  }

  return (
    <Container className="justify-content-center align-items-center">
      <Row>
        <Col style={colStyle}>
          <Card className="image-card" style={{ width: '18rem' }}>
            <Card.Header>
              <Card.Title>
                Step 1 <span style={{ float: 'right' }}><BsFillArrowRightSquareFill /></span>
              </Card.Title>
            </Card.Header>
            <Card.Body className="align-items-center">
              <Nav.Link target="_blank" href={GOOGLE_FORMS_CONTACT_US_URL} rel="no-follow" className="nav-link ml-auto" style={{ color: 'rgb(251,181,20)' }}>Contact Us <span style={{ float: 'right' }}>👋</span></Nav.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col style={colStyle}>
          <Card className="image-card" style={{ width: '18rem' }}>
            <Card.Header>
              <Card.Title>
                Step 2 <span style={{ float: 'right' }}><BsFillArrowRightSquareFill /></span>
              </Card.Title>
            </Card.Header>
            <Card.Body className="align-items-center">
              <Card.Text>Paricipate in a Demo<span style={{ float: 'right' }}>💻</span></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col style={colStyle}>
          <Card className="image-card" style={{ width: '18rem' }}>
            <Card.Header>
              <Card.Title>
                Step 3 <span style={{ float: 'right' }}><BsCheckCircleFill /></span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Join our waiting list <span style={{ float: 'right' }}>📝</span><br/>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

function CallToAction() {
  const colStyle = {
    margin: '1rem',
    color: '#fff',
  }
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ paddingTop: '100px', paddingBottom: '100px' }} >
      <Row>
        <Col style={colStyle}>
          <h1>Are USPS technology requirements stressing you out?</h1>
          <h4 className="subtitle">
            USPS data changes constantly and integrating with their data pipelines can be difficult.
            Mail Carriers small and large are struggling to get compliant. Use Mail Carrier specific
            tools that stay current with industry standards and USPS requirements.
          </h4>
        </Col>
      </Row>
    </Container>
  )
}

function CallToAction2() {
  const colStyle = {
    margin: '1rem',
    color: '#fff'
  }
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ paddingTop: '100px', paddingBottom: '100px' }} >
      <Row>
        <Col style={colStyle}>
          <h1>Don't get road blocked by data. Mail Carrier TMS is here to help</h1>
          <h4 className="subtitle">
            Mail Carrier TMS takes the guess work out of compliance while providing a suite of
            tools designed to automate processes and simplify workflow.
          </h4>
        </Col>
      </Row>
    </Container>
  )
}

function ModuleDescriptions() {

  return (
    <>
      <Container className="justify-content-center align-items-center">
        <Row>
          <Col>
            <FlippableCard front={
              <Card className="image-card" style={{ width: '18rem' }}>
                <Card.Header>
                  <Card.Title>Load Tender</Card.Title>
                </Card.Header>
                <Card.Img src={semiimg} />
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>Blue Yonder Load Tenders</ListGroupItem>
                    <ListGroupItem>GPS Data Push</ListGroupItem>
                    <ListGroupItem>Transportation Management</ListGroupItem>
                    <ListGroupItem>Live Analytics</ListGroupItem>
                  </ListGroup>
                  <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                </Card.Body>
              </Card>
            } back={
              <Card className="image-card" style={{ width: '18rem' }}>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>
                      Load Tender is geared towards Mail Carrier operations and provides AI dispatching tools.
                      These tools build and schedule dispatches according to custom USPS frequencies for Schedule Plates, Manifests, and Load Tenders. Module data meshes with ELD service providers, and overlays dispatch operations with live data for a complete dispatch visual.
                    </ListGroupItem>
                  </ListGroup>
                  <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                </Card.Body>
              </Card>
            } />
          </Col>
          <Col>
            <FlippableCard front={
              <Card className="image-card" style={{ width: '18rem' }}>
                <Card.Header>
                  <Card.Title>Manifest</Card.Title>
                </Card.Header>
                <Card.Img src={docimg} />
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>Evaluate Operation Costs</ListGroupItem>
                    <ListGroupItem>Manage Dispatch</ListGroupItem>
                    <ListGroupItem>Visualize Your Operation</ListGroupItem>
                    <ListGroupItem>See changes over time</ListGroupItem>
                  </ListGroup>
                  <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                </Card.Body>
              </Card>} back={<Card className="image-card" style={{ width: '18rem' }}>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>
                      USPS DRO/LRO manifests are operation schedules and in their raw format can be
                      difficult to use. SYBR's Manifest module breaks down DRO/LRO in seconds.
                      The Manifest Module provides a set of tools to analyze operations,
                      dispatch loads, and analyze cost.
                    </ListGroupItem>
                  </ListGroup>
                  <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                </Card.Body>
              </Card>
              }
            />
          </Col>
          <Col>
            <FlippableCard
              front={
                <Card className="image-card" style={{ width: '18rem' }}>
                  <Card.Header>
                    <Card.Title>5500</Card.Title>
                  </Card.Header>
                  <Card.Img src={graphimg} />
                  <Card.Body>
                    <ListGroup>
                      <ListGroupItem>Drill Down on Issues</ListGroupItem>
                      <ListGroupItem>Automated Reporting</ListGroupItem>
                      <ListGroupItem>Key Performance Indicators</ListGroupItem>
                      <ListGroupItem>Syncs with Operations</ListGroupItem>
                    </ListGroup>
                    <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                  </Card.Body>

                </Card>}
              back={
                <Card className="image-card" style={{ width: '18rem' }}>
                  <Card.Body>
                    <ListGroup>
                      <ListGroupItem>
                        5500 is a powerful application that simplifies record management by providing a user-friendly
                        platform to upload and organize data. It offers the ability to drill down on issues, enabling users to
                        identify and address problems efficiently. With its robust data analysis capabilities, 5500 empowers
                        users to make informed decisions by generating insights from the uploaded records.
                      </ListGroupItem>
                    </ListGroup>
                    <Badge bg="primary"> <GiHorizontalFlip /> <i>Flip</i> </Badge>
                  </Card.Body>
                </Card>
              }
            />
          </Col>
        </Row>
      </Container>
    </>

  )
}

function Endorsements() {
  const colStyle = {
    margin: '3rem'
  }

  return (
    <>
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Col style={colStyle}>
            <h3 style={{ color: '#fff' }}>Endorsements</h3>
          </Col>
        </Row>
      </Container>
      <Container className="justify-content-center align-items-center">
        <Row>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '150px', }}>
              <Card.Img src={mmslogo} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

function BusinessConnections() {
  const colStyle = {
    margin: '2rem'
  }

  return (
    <>
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Col style={colStyle}>
            <h3 style={{ color: '#fff' }}>Current Supported Business Connections</h3>
          </Col>
        </Row>
      </Container>
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={SAMSARA_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={KLEINSCHMIDT_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={USPS_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={EIA_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={NWS_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={GMAPS_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={FOURKITES_IMG} />
              </Card.Body>
            </Card>
          </Col>
          <Col style={colStyle}>
            <Card className="image-card" style={{ width: '300px', height: '200px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Card.Img src={PCMILER_IMG} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

function SOC2() {
  const colStyle = {
    margin: '1rem',
    color: '#fff'
  }
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ padding: '50px' }}>
      <Row>
        <Col style={colStyle}>
          <h1>From pick up, to delivery, to reconciliation - Mail Carrier TMS provides the tools and security that Mail Carriers need.</h1>
          <h4 className="subtitle">
            Your security and the security of your data matters. 
            Mail Carrier TMS independently servers its customers, and provides you a completely unique operational environment: to 
            ensure your data, the interests of your organization, and privacy are protected.
          </h4>
        </Col>
      </Row>
    </Container>
  )
}

const MailCarrierTMSPage = ({ header, footer }) => {
  return (
    <div style={{
      backgroundColor: 'rgba(22,81,169, 0.75)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      minWidth: '375px'
    }} >
      {header}
      <Message />
      <CallToActionPlan />
      <CallToAction />
      <CallToAction2 />
      <ModuleDescriptions />
      <BusinessConnections />
      <SOC2 />
      <Endorsements />
      {footer}
    </div>
  )
}

export default MailCarrierTMSPage;