import {Container} from 'react-bootstrap'

function NotFound() {
    return (
      <Container fluid style={{minHeight:'85vh'}}>
        <h1>Not found</h1>
      </Container>
    )
  }

export default NotFound